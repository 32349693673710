<template>
    <admin-dashboard-layout>
        <v-btn text :to="{name: 'admin.price-plans.index'}">
            <v-icon left >mdi-arrow-left</v-icon>
            Back to price list
        </v-btn>
        <v-progress-linear v-if="fetchingPlan" indeterminate color="primary"></v-progress-linear>
        <v-card v-else class="mt-6" flat outlined>
            <v-form>
                <v-row>
                    <v-col class="pa-10">
                        <v-text-field label="Price Plan Name"  v-model="pricePlan.name"></v-text-field>
                        <v-text-field
                            label="Price"
                            v-model="pricePlan.price"
                            prefix="$"
                            :disabled="pricePlanExists"
                            type="number"
                        ></v-text-field>
                        <v-select
                            :disabled="pricePlanExists"
                            :items="billingIntervals"
                            item-text="label"
                            item-value="value"
                            v-model="pricePlan.interval"
                            label="Billing Interval"
                        ></v-select>
                        <v-text-field
                            label="Responses limit per month"
                            v-model="pricePlan.restrictions.submission_count"
                            prefix="#"
                            default="500"
                            type="number"
                        ></v-text-field>
                        <v-text-field
                            label="Seats limit"
                            v-model="pricePlan.restrictions.seats"
                            prefix="#"
                            default="3"
                            type="number"
                        ></v-text-field>
                        <v-btn elevation="0" color="primary" :loading="loading" :disabled="loading" @click="handleSave">{{pricePlanExists?"Update": 'Create Price Plan'}}</v-btn>
                    </v-col>
                    <v-col class="pa-10">
                        <div class="flex-grow-0">
                            <div class="text-h6">Available Features</div>
                            <div class="d-flex flex-column">
                                <template v-for="feature in featuresList" >
                                    <v-slider
                                        :key="feature.key"
                                        v-if="feature.key==='max_audio_response_time'"
                                        v-model="pricePlan.features[feature.key]"
                                        :label="feature.label+' (seconds)'"
                                        :min="150"
                                        :max="2400"
                                        :step="10"
                                        hide-details
                                        thumb-label="always"
                                        class="my-4"
                                    ></v-slider>
                                    <v-text-field
                                        v-else-if="feature.key==='probing_monthly_limit' && pricePlan.features.probing"
                                        :key="feature.key"
                                        :value="pricePlan.features[feature.key]"
                                        type="number"
                                        hide-details
                                        class="my-4"
                                        @input="value =>validateInteger(value, feature.key)"
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <v-checkbox
                                        v-else
                                        :key="feature.key"
                                        v-model="pricePlan.features[feature.key]"
                                        :label="feature.label"
                                        hide-details
                                        dense
                                    ></v-checkbox>
                                </template>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import PricePlan from "../../../models/PricePlan";
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import { customPriceCreationDefaultFeatures } from '../../../utils/plansContext';

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data() {
            return {
                fetchingPlan: false,
                loading: false,
                pricePlan: {
                    interval: 'month',
                    is_active: 1,
                    restrictions: {
                        submission_count: 25,
                        seats: 1
                    },
                    features: {
                        ...customPriceCreationDefaultFeatures
                    }
                },
                
                billingIntervals: [
                    {
                        label:'Monthly',
                        value: 'month'
                    },
                    {
                        label: 'Annually',
                        value: 'year'
                    }
                ]
            }
        },
        methods:{
            async handleSave() {
                try {
                    const restrictions = {
                        submission_count: Number(this.pricePlan.restrictions.submission_count),
                        seats: Number(this.pricePlan.restrictions.seats),
                    }
    
                    const pricePlan = new PricePlan({...this.pricePlan, restrictions: JSON.stringify(restrictions)})
                    this.loading = true
                    this.pricePlan = await pricePlan.save()
                    if(this.pricePlan){
                        this.$router.push({name: 'admin.price-plans.edit', params: {id: this.pricePlan.id}})
                    }
                    this.loading = false
                } catch (error) {
                    this.loading = false
                    console.error(error)
                }

            },
            validateInteger(value, featureKey) {
                try {
                    if (parseInt(value) === parseFloat(value)) {
                        // Only update the value if it's an integer or empty string
                        this.pricePlan.features[featureKey] = Number(value);
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        },
        computed: {
            pricePlanExists: function () {return !!this.pricePlan && !!this.pricePlan.id},
            featuresList: function () {
                return Object.keys(this.pricePlan.features).map(key => {
                    return {
                        label: key.replace(/_/g, ' '),
                        key,
                        value: this.pricePlan.features[key]
                    }
                })
            }
        },
        async mounted() {
            if (this.$route.params.id) {
                try {
                    this.fetchingPlan = true
                    const pricePlanId = this.$route.params.id
                    const pricePlan = await PricePlan.find(pricePlanId)

                    this.pricePlan = {
                        ...pricePlan,
                        features: {
                            ...customPriceCreationDefaultFeatures,
                            ...pricePlan.features
                        }
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    this.fetchingPlan = false
                }
            }

        },
    }
</script>
